import React, { useState } from "react";
import { CommingSoonProps } from "./types";
import "./stylesCommingSoon.css";
import Logo from "../CommonComponents/Logo";
import { BASE_URL, ENDPOINTS, IMAGE_KIT_URL, IMAGE_NAMES } from "../../utils";
import InputWithButton from "../CommonComponents/InputWithButton";
import usePost from "../../CustomHooks/usePost";
import { toast } from "react-toastify";

const CommingSoonWeb: React.FC<CommingSoonProps> = ({}) => {
  const { loading, postData } = usePost<{ message: string }>();

  const [input, setInput] = useState<string>("");

  const onFormSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newValue = input;

    // Check if the input value matches the email pattern
    if (emailRegex.test(newValue)) {
      postData(`${BASE_URL}${ENDPOINTS.NOTIFY_USER_ON_LAUNCH}`, {
        email: input,
      })
        .then((success) => {
          console.log(success);
          toast.success("We will notify you soon!");
        })
        .catch((e) => {
          toast.warn("Something went wrong");
        });
    } else {
      // Handle invalid email (optional - show a message or leave input empty)
      toast.warn("Enter a valid email");
    }
  };

  return (
    <div
      className="container"
      style={{
        backgroundImage: `url(${IMAGE_KIT_URL}${IMAGE_NAMES.DESKTOP_BACKGROUND})`,
      }}
    >
      <Logo fontSize={48} height={69} width={330} />
      <div className="image-container">
        <div className="horizontal-holder">
          <img
            width={"90%"}
            src={`${IMAGE_KIT_URL}${IMAGE_NAMES.GET_NOTIFIED}`}
          />
          <InputWithButton
            inputValue={input}
            setInput={setInput}
            onSubmit={onFormSubmit}
            isDisabled={loading}
            customStyles={{ marginTop: 12 }}
            placeHolder="Enter your email address"
            buttonText="Notify me"
          />
        </div>
        <img height={600} src={`${IMAGE_KIT_URL}${IMAGE_NAMES.BRAND_IMAGE}`} />
      </div>
    </div>
  );
};

export default CommingSoonWeb;
