// src/pages/About.tsx
import React from "react";
import { useMediaQuery } from "react-responsive";
import CommingSoonWeb from "./CommingSoonWeb";
import CommingSoonMobile from "./CommingSoonMobile";

const CommingSoon: React.FC<any> = ({}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return isMobile ? <CommingSoonMobile /> : <CommingSoonWeb />;
};

export default CommingSoon;
