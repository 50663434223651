export const IMAGE_KIT_URL =
  "https://ik.imagekit.io/flexigym2024/App%20assets/";

export const IMAGE_NAMES = {
  LOGO_WITH_NAME: "fg-logo.png",
  DESKTOP_BACKGROUND: "desktop-bg.png",
  GET_NOTIFIED: "get-notified.png",
  BRAND_IMAGE: "brand-image.png",
  MOBILE_BG: "mobile-bg.png",
};

export const BASE_URL = "https://api-services.flexigym.fit";

export const ENDPOINTS = {
  NOTIFY_USER_ON_LAUNCH: "/v1/auth/user/notifyOnLaunch",
};
