import React, { useState } from "react";
import "./styles.css";
import { useMediaQuery } from "react-responsive";

type Props = {
  placeHolder?: string;
  customStyles?: any;
  buttonText?: string;
  inputValue: string;
  setInput: (value: string) => void;
  onSubmit: () => void;
  isDisabled?: boolean;
};

const InputWithButton: React.FC<Props> = ({
  placeHolder = "Enter a text",
  customStyles = {},
  buttonText = "Press",
  inputValue,
  setInput,
  onSubmit,
  isDisabled = false,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) return;
    setInput(e.target.value);
  };

  if (isMobile) {
    return (
      <div style={customStyles} className="input-container-mobile">
        <input
          className="styled-input-mobile"
          type="text"
          value={inputValue}
          onChange={handleTextChange}
          placeholder={placeHolder}
        />
        <button onClick={onSubmit} className="styled-button-mobile">
          {buttonText}
        </button>
      </div>
    );
  }
  return (
    <div style={customStyles} className="input-container">
      <input
        value={inputValue}
        onChange={handleTextChange}
        className="styled-input"
        type="text"
        placeholder={placeHolder}
      />

      <button onClick={onSubmit} className="styled-button">
        {buttonText}
      </button>
    </div>
  );
};

export default InputWithButton;
