import { useState } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const usePost = <T,>(config?: AxiosRequestConfig) => {
  const [loading, setLoading] = useState(false);

  const postData = async (url: string, body: any) => {
    setLoading(true);

    try {
      const response: AxiosResponse<T> = await axios.post(url, body, config);
      return response.data;
    } catch (err: any) {
      return (
        err.response?.data?.message || err.message || "Something went wrong"
      );
    } finally {
      setLoading(false);
    }
  };

  return { loading, postData };
};

export default usePost;
