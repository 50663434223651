import React, { useState } from "react";
import { CommingSoonProps } from "./types";
import Logo from "../CommonComponents/Logo";
import "./stylesCommingSoon.css";
import { BASE_URL, ENDPOINTS, IMAGE_KIT_URL, IMAGE_NAMES } from "../../utils";
import InputWithButton from "../CommonComponents/InputWithButton";
import usePost from "../../CustomHooks/usePost";
import { toast } from "react-toastify";

const CommingSoonMobile: React.FC<CommingSoonProps> = ({}) => {
  const { loading, postData } = usePost<{ message: string }>();

  const [input, setInput] = useState<string>("");

  const onFormSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newValue = input;

    // Check if the input value matches the email pattern
    if (emailRegex.test(newValue)) {
      postData(`${BASE_URL}${ENDPOINTS.NOTIFY_USER_ON_LAUNCH}`, {
        email: input,
      })
        .then((success) => {
          console.log(success);
          toast.success("We will notify you soon!");
        })
        .catch((e) => {
          toast.warn("Something went wrong");
        });
    } else {
      // Handle invalid email (optional - show a message or leave input empty)
      toast.warn("Enter a valid email");
    }
  };

  return (
    <div
      className="container-mobile"
      style={{
        backgroundImage: `url(${IMAGE_KIT_URL}${IMAGE_NAMES.MOBILE_BG})`,
      }}
    >
      <Logo width={200} height={36} fontSize={28} />
      <img
        className="image-mobile-1"
        width={"80%"}
        src={`${IMAGE_KIT_URL}${IMAGE_NAMES.GET_NOTIFIED}`}
      />
      <InputWithButton
        inputValue={input}
        setInput={setInput}
        customStyles={{ marginTop: "6%" }}
        placeHolder="Enter your email address"
        buttonText="Notify me"
        onSubmit={onFormSubmit}
        isDisabled={loading}
      />
      <img
        className="image-mobile-2"
        height={"50%"}
        src={`${IMAGE_KIT_URL}${IMAGE_NAMES.BRAND_IMAGE}`}
      />
    </div>
  );
};

export default CommingSoonMobile;
