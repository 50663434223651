import React from "react";
import "./styles.css";
import { IMAGE_KIT_URL, IMAGE_NAMES } from "../../utils";
import { useMediaQuery } from "react-responsive";

type LogoProps = {
  width?: number;
  height?: number;
  fontSize?: number;
};

const Logo: React.FC<LogoProps> = ({ width, height, fontSize }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const logoWidth = !width ? (isMobile ? 200 : 330) : width;
  const logoHeight = !height ? (isMobile ? 36 : 69) : height;

  return (
    <div>
      <img
        height={logoHeight}
        width={logoWidth}
        src={`${IMAGE_KIT_URL}${IMAGE_NAMES.LOGO_WITH_NAME}`}
      />
    </div>
  );
};

export default Logo;
