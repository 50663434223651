// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import CommingSoon from "../Modules/CommingSoon/index";
import { ROUTE_NAMES } from "./routes";
import TermsOfUse from "../Modules/Policies/TermsOfUse";
import PrivacyPolicy from "../Modules/Policies/PrivacyPolicy";
import DisclaimerPolicy from "../Modules/Policies/DisclaimerPolicy";
import RefundPolicy from "../Modules/Policies/RefundPolicy";

const RouterRoot: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={CommingSoon} />
        <Route path={ROUTE_NAMES.TERMS_OF_USE} Component={TermsOfUse} />
        <Route path={ROUTE_NAMES.PRIVACY_POLICY} Component={PrivacyPolicy} />
        <Route path={ROUTE_NAMES.DISCLAIMER} Component={DisclaimerPolicy} />
        <Route
          path={ROUTE_NAMES.REFUND_CANCELLATION}
          Component={RefundPolicy}
        />
      </Routes>
    </Router>
  );
};

export default RouterRoot;
